import { Modal } from '@mui/material';
import React, { useState } from 'react';
import '../../assets/styling/component/generateAuthToken.scss';
import Loader from '../../utils/loader';
import '../../assets/styling/component/Main.scss'
import { generateToken } from '../../services/nodeServices';

export const GenerateAuthToken = () => {
    const [isTokenGenerated, setTokenGenerated] = useState(false);
    const [isLoader, setLoader] = useState(false);


    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
      generateToken().then((result) => {
        if(result){
            setLoader(false);
            setTokenGenerated(true)
        }
    })
    }

    return <>
    {isLoader &&  <Loader />}
    <Modal open={!isLoader}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description" 
    className="modalContainer"
    >
    <div className='boxWrapper'>
       {!isTokenGenerated && <h4 className='textInfo'>Authentication token is missing !!</h4>}
        <p className='description'>{
            !isTokenGenerated 
            ? "Generate New Login Token" 
            : "Login Token is generated & Sent to the Lab Instance user's email ID You may close this window"
        }</p>
        {!isTokenGenerated && <form className='formWrapper' onSubmit={handleFormSubmit}>
            <button className='btn blueBtn'>Generate Token</button>
        </form>
        }
    </div>
    </Modal>
    </>
}