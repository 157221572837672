import ReactGA from 'react-ga4';
import TagManager from "react-gtm-module";


export const EventPageView = (props)=>{
    ReactGA.pageview(props)

}

export const CaptureClickEvent =(label)=>{
    ReactGA.event({
        category: 'User Interaction',
        action: 'Click_Event',
        label: label,
  });

}

export const ScrollEvent =(scrollPercent)=>{
    TagManager.trackEvent('page_scroll', {
        scroll_percent: scrollPercent,
      });
}