import { CircularProgress } from "@mui/material";

 const Loader = () => {
    return (
        <div>
            <div className="overlay"></div>
            <CircularProgress className="loaderIcon" />
        </div>
    )
}

export default Loader;