/* eslint-disable react/no-unescaped-entities */
import '../../assets/styling/component/tab-component/ReferenceTab.scss';
import { Typography } from '@mui/material';

function ReferenceTab() {
    return (
        <div className="reference-main ">
            <Typography variant="h6" className="reference-title">
                Controller CLI Configuration Examples
            </Typography>
            <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                sx={{ alignSelf: 'flex-start' }}
            >
                Below are controller CLI configuration examples for this lab envrionment.
            </Typography>
            <div className={`reference-ex-div`}>
                <br />
                ! <br />! DANZ Monitoring Fabric 8.0.2 (dmf-8.0.2 #12) <br />! Current Time:
                2021-08-19 20:58:55 UTC <br />!<br /> version 1.0 <br /> <br />! ntp
                <br /> ntp server 0.bigswitch.pool.ntp.org <br /> ntp server
                1.bigswitch.pool.ntp.org <br />
                ntp server 2.bigswitch.pool.ntp.org <br />
                ntp server 3.bigswitch.pool.ntp.org <br /> <br />! local <br /> local node
                <br /> &nbsp;&nbsp;hostname controller-1 <br />
                &nbsp;&nbsp;interface management <br /> &nbsp;&nbsp;&nbsp;&nbsp;! <br />
                &nbsp;&nbsp;&nbsp;&nbsp;ipv4 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ip 10.10.12.20/24 gateway 10.10.12.1 <br />{' '}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;method manual <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;dns server 10.10.0.1 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;! <br />
                &nbsp;&nbsp;&nbsp;&nbsp;ipv6
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;method auto <br />
                <br />! user <br />
                user admin <br />
                &nbsp;&nbsp;full-name 'Default admin' <br />
                &nbsp;&nbsp;hashed-password
                method=PBKDF2WithHmacSHA512,salt=Tl6vL14_6UHee_HdUN5EMA,rounds=25000,ph=true,gXJh
                TCtNgJRCQ9DmMLHOuLQ9sfQoPKeajK4N1aF7LPSllraBjSDdOJPbZuYmLNv5h3aRpU7ozi9HQkkbsiPknQ
                <br />
                <br /> ! group <br />
                group admin <br />
                &nbsp;&nbsp;associate user admin <br />
                <br />
                group read-only <br /> <br />! aaa <br />
                aaa accounting exec default start-stop local <br />
                <br />! controller
                <br />
                controller <br />
                &nbsp;&nbsp;cluster-name dmf-cluster <br />
                &nbsp;&nbsp;access-control <br />
                &nbsp;&nbsp;&nbsp;&nbsp;! <br />
                &nbsp;&nbsp;&nbsp;&nbsp;access-list api <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 permit from ::/0 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 permit from 0.0.0.0/0 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;!
                <br /> &nbsp;&nbsp;&nbsp;&nbsp;access-list gui
                <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 permit from ::/0 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 permit from 0.0.0.0/0 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;!
                <br /> &nbsp;&nbsp;&nbsp;&nbsp;access-list ntp <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 permit from ::/0 <br />{' '}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 permit from 0.0.0.0/0 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;! <br />
                &nbsp;&nbsp;&nbsp;&nbsp;access-list ssh <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 permit from ::/0 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 permit from 0.0.0.0/0 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;!
                <br /> &nbsp;&nbsp;&nbsp;&nbsp;access-list vce-api <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 permit from ::/0 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 permit from 0.0.0.0/0 <br />
                <br />! switch <br />
                switch Core-Switch <br />
                &nbsp;&nbsp;mac 00:00:00:00:00:0b <br />
                <br />
                switch Delivery-Switch <br />
                &nbsp;&nbsp;mac 00:00:00:00:00:0c <br />
                &nbsp;&nbsp;! <br />
                &nbsp;&nbsp;interface ethernet2 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;role delivery interface-name WIRESHARK1
                <br />
                &nbsp;&nbsp;! <br />
                &nbsp;&nbsp;interface ethernet3 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;role delivery interface-name IDS1 <br />
                <br />
                switch Filter-Switch <br />
                &nbsp;&nbsp;mac 00:00:00:00:00:0a <br />
                &nbsp;&nbsp;!
                <br />
                &nbsp;&nbsp;interface ethernet2 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;role filter interface-name FILTER1 <br />
                <br />! policy <br />
                policy POLICY1 <br />
                &nbsp;&nbsp;action forward <br />
                &nbsp;&nbsp;delivery-interface IDS1
                <br />
                &nbsp;&nbsp;delivery-interface WIRESHARK1
                <br /> &nbsp;&nbsp;filter-interface FILTER1
                <br /> &nbsp;&nbsp;start on-date-time 2021-08-19T20:56:44+00:00 duration 0 <br />
                &nbsp;&nbsp;1 match ip 1 <br />
                <br />
                policy POLICY2 <br />
                &nbsp;&nbsp;action forward
                <br />
                &nbsp;&nbsp;delivery-interface WIRESHARK1 <br />
                &nbsp;&nbsp;filter-interface FILTER1 <br />
                &nbsp;&nbsp;start on-date-time 2021-08-19T20:58:14+00:00 duration 0 <br />
                &nbsp;&nbsp;1 match tcp <br />
                controller-1#
                <br />
                <br />
            </div>
        </div>
    );
}

export default ReferenceTab;
