import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import _ from 'lodash';
import { CaptureClickEvent } from '../google-analytics/CaptureEvents';
import { isAnalyaticsRequired } from '../../utils/commonUtil';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Tooltip } from '@mui/material';

function Sidebar(props) {
    const [showMenuItem, setMenuItem] = useState(true);

    const toggleMenuItems = () => {
        setMenuItem(!showMenuItem);
    };

    // Change Section As per indexs
    const handlePrevNextClick = (status, value, event, label) => {
        event.stopPropagation();
        if (status === 'heading') {
            props.changeMdId(value);
            props.changeSubHeadingId(1);
            props.nextPrevData.map((npObj, index) => {
                if (value === npObj.mainIndex && npObj.subIndex === 0) {
                    props.changeNextPrevId(index);
                }
            });
        } else {
            props.changeSubHeadingId(value + 1);
            props.nextPrevData.map((npObj, index) => {
                if (props.mdId === npObj.mainIndex && value === npObj.subIndex) {
                    props.changeNextPrevId(index);
                }
            });
        }
        document.getElementById('mdWrapper').scrollTo({
            top: 0,
        });

        if (isAnalyaticsRequired()) {
            let labName = !_.isUndefined(process.env.REACT_APP_TOPOLOGY_HEADING)
                ? process.env.REACT_APP_TOPOLOGY_HEADING
                : 'DMF Overview';
            CaptureClickEvent(labName + ' : ' + label);
        }
    };

    const showSubHeadingName = (subHeading, mainIndex) => {
        return (
            <ul className={`${subHeading.length !== 0 && 'subHeaderList'}`}>
                {subHeading.map((subHeadObj, index) => showListItem(subHeadObj, index, mainIndex))}
            </ul>
        );
    };

    const showListItem = (item, index, mainIndex) => {
        let removeSpecialChar = item.slice(4);
        let indexs = mainIndex + 1 + '.' + (index + 1) + '. ';
        return (
            <li
                key={index.toString()}
                onClick={(e) =>
                    handlePrevNextClick(
                        'subHeading',
                        index,
                        e,
                        removeSpecialChar.toLowerCase().substring(0, 12) === 'introduction'
                            ? 'INTRODUCTION'
                            : removeSpecialChar.replace(/[^a-zA-Z / 0-9 -]/g, ''),
                    )
                }
                className={`${
                    index + 1 === props.subHeadingId
                        ? 'subSectionListItem active'
                        : 'subSectionListItem'
                }`}
            >
                <span className="listNumber" style={{ marginRight: '6px' }}>
                    {indexs}
                </span>
                <span className="subHeadingTitle">
                    {removeSpecialChar.toLowerCase().substring(0, 12) === 'introduction'
                        ? 'INTRODUCTION'
                        : removeSpecialChar.replace(/[^a-zA-Z / 0-9 -]/g, '')}
                    {/* <span
                className={`${
                    index + 1 === props.subHeadingId
                        ? 'subSectionListBar active'
                        : 'subSectionListBar'
                }`}
            ></span> */}
                </span>
            </li>
        );
    };

    // Show Previous Next Titles
  
    const showSectionTitle = (value) => {
        let removeLastCharforPrev =
            props.nextPrevData[
                props.nextPrevId === 0 ? 0 : props.nextPrevId - 1
            ].subTitle.substring(0, 3) === 'n##'
                ? props.nextPrevData[
                      props.nextPrevId === 0 ? 0 : props.nextPrevId - 1
                  ].subTitle.slice(4)
                : props.nextPrevData[props.nextPrevId === 0 ? 0 : props.nextPrevId - 1].subTitle;

        let removeLastCharForNext =
            props.nextPrevData[
                props.nextPrevId === props.nextPrevData.length - 1
                    ? props.nextPrevData.length - 1
                    : props.nextPrevId + 1
            ].subTitle.substring(0, 3) === 'n##'
                ? props.nextPrevData[
                      props.nextPrevId === props.nextPrevData.length - 1
                          ? props.nextPrevData.length - 1
                          : props.nextPrevId + 1
                  ].subTitle.slice(4)
                : props.nextPrevData[
                      props.nextPrevId === props.nextPrevData.length - 1
                          ? props.nextPrevData.length - 1
                          : props.nextPrevId + 1
                  ].subTitle;

        let prevText = removeLastCharforPrev;
        let nextText = removeLastCharForNext;
        let sectionName;

        if (value === 'prev') {
            sectionName = (
                <div>
                    <span className="title" style={{fontSize:'13px'}}>Previous</span> <br />
                    <span className="heading" style={{fontSize:'14px'}}>
                        {prevText.toLowerCase().substring(0, 12) === 'introduction' ? 'INTRODUCTION' : prevText}
                    </span>
                </div>
            );
        } else {
            sectionName = (
                <div className="nextPrevContainer">
                    <span className="title" style={{fontSize:'13px'}}>Next</span> <br />
                    <span className="heading" style={{fontSize:'14px'}}>
                        {nextText.toLowerCase().substring(0, 12) === 'introduction' ? 'INTRODUCTION' : nextText}
                    </span>
                </div>
            );
        }

        return sectionName;
    };

    const changeSection = (status, selectedItem, index) => {
        if (selectedItem.subIndex === -1) {
            //   setmdId(selectedItem.mainIndex);
            //   setSubHeadingId(-1);
            //   setNextPrevId(selectedItem.mainIndex);
            props.changeMdId(selectedItem.mainIndex);
            props.changeSubHeadingId(-1);
            props.changeNextPrevId(selectedItem.mainIndex);
            if (isAnalyaticsRequired()) {
                CaptureClickEvent(
                    'Clicked On Previous Button ' +
                        selectedItem.subTitle.replace('n##', 'section: '),
                );
            }
        } else {
            //   setmdId(selectedItem.mainIndex);
            //   setSubHeadingId(selectedItem.subIndex + 1);
            //   setNextPrevId(index);
            props.changeMdId(selectedItem.mainIndex);
            props.changeSubHeadingId(selectedItem.subIndex + 1);
            props.changeNextPrevId(index);
            if (isAnalyaticsRequired()) {
                let labName = !_.isUndefined(process.env.REACT_APP_TOPOLOGY_HEADING)
                    ? process.env.REACT_APP_TOPOLOGY_HEADING
                    : 'DMF Overview';
                CaptureClickEvent(
                    labName +
                        ' : ' +
                        (status === 'prev'
                            ? 'Clicked On Previous Button '
                            : 'Clicked On Next Button ') +
                        selectedItem.subTitle.replace('n##', 'section -'),
                );
            }
        }
        // handleScrollToTop()
    };

    return (
        <>
            <ul
                className="mdSectionList"
                style={{
                    minWidth: showMenuItem ? '280px' : '50px',
                }}
            >
                <div className="listWrapper">
                    <button
                        className={'icon-background'}
                        style={{ marginBottom: '16px', height: '30px', width: '30px' }}
                        onClick={() => toggleMenuItems()}
                    >
                        {!showMenuItem ? <MenuIcon fontSize="medium" /> : <CloseIcon />}
                    </button>
                    {showMenuItem &&
                        props.mdSectionName.map((mdsecObj, index) => (
                            <li
                                key={index.toString()}
                                className={`mdSectionListItem`}
                                onClick={(e) =>
                                    handlePrevNextClick('heading', index, e, mdsecObj.heading)
                                }
                            >
                                {!_.isUndefined(mdsecObj.heading) && (
                                    <span
                                        style={{ display: 'flex' }}
                                        className={`${
                                            index === props.mdId ? 'bgActive heading' : 'heading'
                                        }`}
                                    >
                                        <span style={{ display: 'flex' }}>
                                            <span style={{ marginRight: '6px' }}>
                                                {index + 1 + '. '}
                                            </span>
                                            <span>{mdsecObj.heading}</span>
                                        </span>
                                        <KeyboardArrowUpIcon
                                            className={`${
                                                index === props.mdId ? 'activeArrow' : 'upArrow'
                                            }`}
                                        />
                                    </span>
                                )}
                                {props.mdId === index &&
                                    showSubHeadingName(mdsecObj.subHeading, index)}
                            </li>
                        ))}
                </div>
                {showMenuItem &&
                <div className="nextPrevContainer">
                    {props.nextPrevData.length !== 0 && (
                        <>
                            <div style={{ opacity: props.nextPrevId !== 0 ? 1 : 0.5 }}>
                                <Tooltip
                                    title={props.nextPrevId !== 0 ? showSectionTitle('prev') : ''}
                                    arrow
                                    placement="bottom-end"
                                >
                                    <span
                                        className="next arrow"
                                        style={{
                                            cursor: props.nextPrevId !== 0 ? 'pointer' : 'no-drop',
                                        }}
                                        onClick={() =>
                                            changeSection(
                                                'prev',
                                                props.nextPrevData[
                                                    props.nextPrevId === 0
                                                        ? 0
                                                        : props.nextPrevId - 1
                                                ],
                                                props.nextPrevId === 0 ? 0 : props.nextPrevId - 1,
                                            )
                                        }
                                    >
                                        <ArrowBackIcon />
                                    </span>
                                </Tooltip>
                            </div>
                            <div
                                style={{
                                    opacity:
                                        props.nextPrevData.length - 1 !== props.nextPrevId
                                            ? 1
                                            : 0.5,
                                }}
                            >
                                <Tooltip
                                    title={
                                        props.nextPrevData.length - 1 !== props.nextPrevId
                                            ? showSectionTitle('next')
                                            : ''
                                    }
                                    arrow
                                    placement="bottom-start"
                                >
                                    <span
                                        className="prev arrow"
                                        style={{
                                            cursor:
                                                props.nextPrevData.length - 1 !== props.nextPrevId
                                                    ? 'pointer'
                                                    : 'no-drop',
                                        }}
                                        onClick={() =>
                                          props.nextPrevData.length - 1 !== props.nextPrevId && changeSection(
                                                'next',
                                                props.nextPrevData[
                                                    props.nextPrevId ===
                                                    props.nextPrevData.length - 1
                                                        ? props.nextPrevData.length - 1
                                                        : props.nextPrevId + 1
                                                ],
                                                props.nextPrevId + 1,
                                            ) 
                                        }
                                    >
                                        <ArrowForwardIcon />
                                    </span>
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>
}
            </ul>
        </>
    );
}

export default Sidebar;
