import { Box, Modal } from '@mui/material';
import ReferenceTab from '../tab-component/ReferenceTab';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55vw',
    height: '80vh',
    bgcolor: 'background.paper',
    border: '1px solid #585858',
    boxShadow: 24,
    borderRadius: 2,
    paddingX: '2vw',
};

function ReferenceModal(prop) {
    const { isOpen, closeReferenceModal } = prop;

    return (
        <Modal
            open={isOpen}
            onClose={closeReferenceModal}
            aria-labelledby="reference-modal-title"
            aria-describedby="reference-modal-description"
        >
            <Box sx={style}>
                <ReferenceTab />
            </Box>
        </Modal>
    );
}

export default ReferenceModal;
