import React, { useEffect, useState } from 'react';
import DirectionContent from '../tab-component/DirectionContent';
import Sidebar from '../tab-component/Sidebar';
import Direction from '../../../src/direction.md'
import _ from 'lodash';
import '../../assets/styling/component/tab-component/MainDirectionMd.scss';
import '../../assets/styling/component/tab-component/Sidebar.scss';
import CloudVisionLabs from '../tab-component/CloudVisionLabs';
import { DRAWER_WIDTH } from './DrawerComponent';
import HandsOnLabTab from '../tab-component/HandsOnLabTab';
import { isCVPorNot } from '../../utils/commonUtil';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import cv_cueImg from '../../../src/assets/images/cv_cueImg.png';
import bgImg from '../../../src/assets/images/bgImg.png';

let splitData, newData;

function MainDirectionMd(props) {
    const [splitedmdData, setSplitedmdData] = useState([]);
    const [visibleScrollToTopbtn, setVisibleBtn] = useState(false);
    const [mdSectionName, setmdSectionName] = useState([]);
    const [nextPrevData, setNextPrevData] = useState([]);
    const [mdId, setmdId] = useState(0);
    const [subHeadingId, setSubHeadingId] = useState(1);
    const [nextPrevId, setNextPrevId] = useState(0);
    const [isOpenDrawer, setDrawerValue] = useState(true);
    const [showFullImg, setShowFullImg] = useState(false);


    useEffect(() => {
        document.getElementById('mdWrapper').addEventListener('scroll', toggleScroller);
        getmdFileData();
    }, []);

    const toggleScroller = () => {
        if (document.getElementById('mdWrapper').scrollTop > 300) {
            setVisibleBtn(true);
        } else {
            setVisibleBtn(false);
        }
    };

    // Get Md File data
    const getmdFileData = () => {
        fetch(Direction)
            .then((res) => res.text())
            .then((md) => {
                newData = JSON.stringify(md);
                splitData = newData.split('---');
                setSplitedmdData(splitData);
                // setMdData(md);
                updateSectionNames();
            });
    };

    // Add Page Number next to h2 tag
    const addPageNumber = (newStr) => {
        // let commaSplitedData = newStr.split(',');
        let commaSplitedData = JSON.parse(newStr);
        let updatedMdArr = [];
        commaSplitedData.map((scObj) => {
            if (scObj.slice(0, 5) === '"n## ') {
                if (scObj.replace(/[/\\]/g, '').charAt() === 'n') {
                    let str = scObj.replace('"', '').slice(1) + '\\n';
                    str.length > 1 && updatedMdArr.push(str);
                }
            } else {
                if (scObj.replace(/[/\\]/g, '').charAt() === 'n') {
                    let str = scObj.replace('"', '').slice(1) + '\\n';
                    str.length > 1 && updatedMdArr.push(str);
                } else {
                    let str = scObj.replace(/[/\\]/g, '');
                    str.length > 1 && updatedMdArr.push(str);
                }
            }
        });
        return updatedMdArr.join('');
    };

    // Seperate all section names
    const updateSectionNames = () => {
        let newDataStore = [];
        let sectionData = [];
        let subHeadingData = [];
        splitData.map((spObj, index) => {
            let subSectionData = [];
            let headingSubHeadingJson = {};
            let newStr = JSON.stringify(splitData[index].split('\\'));
            newDataStore.push(addPageNumber(newStr).split(/(?=n## )/g));
            let filterWtStr =
                JSON.stringify(newStr).length > 1 &&
                JSON.parse(newStr).filter((str) => str.length > 2);
            if (!_.isUndefined(filterWtStr[0])) {
                let distributedSecData;
                let removeSpecialChar = filterWtStr[0].replace(/[^a-zA-Z / 0-9 \- ()]/g, '');
                distributedSecData =
                    filterWtStr[0].charAt() === 'n'
                        ? removeSpecialChar.slice(1)
                        : removeSpecialChar;
                headingSubHeadingJson.heading = distributedSecData.trimStart();
                sectionData.push(distributedSecData.trimStart());
            }

            subSectionData = filterWtStr.filter((ftObj) => ftObj.slice(0, 4) === 'n## ');
            headingSubHeadingJson.subHeading = subSectionData;
            subHeadingData.push(headingSubHeadingJson);
        });
        setmdSectionName(subHeadingData);
        convertSubSections(newDataStore);
        updatePrevNextData(subHeadingData);
    };

    const updatePrevNextData = (listItem) => {
        let subNextPrevData = [];
        let updateListItem = listItem.filter(obj => !_.isUndefined(obj.heading));
        updateListItem.map((itemObj, index) => {
            if (itemObj.subHeading.length === 0) {
                let subNextPrevDataJSON = {};
                subNextPrevDataJSON.mainIndex = index;
                subNextPrevDataJSON.heading = itemObj.heading;
                subNextPrevDataJSON.subIndex = 0;
                subNextPrevDataJSON.subTitle = itemObj.heading;
                subNextPrevData.push(subNextPrevDataJSON);
            } else {
                itemObj.subHeading.map((subItemObj, subIndex) => {
                    let subNextPrevDataJSON = {};
                    subNextPrevDataJSON.mainIndex = index;
                    subNextPrevDataJSON.heading = itemObj.heading;
                    subNextPrevDataJSON.subIndex = subIndex;
                    subNextPrevDataJSON.subTitle = subItemObj;
                    subNextPrevData.push(subNextPrevDataJSON);
                });
            }
        });
        setNextPrevData(subNextPrevData);
    };


    //Seperate out sub sections
    const convertSubSections = (newDataStore) => {
        let updatedSubSection = [];
        newDataStore.map((newscobj, mainIndex) => {

            if (newscobj[0].length !== 0) {
                let newscUpdated = [];
                newscobj.map((scObj, index) => {

                    if (newscobj[0].length !== 0) {
                        if (index === 0) {
                            scObj = scObj.slice(0, 4) === '"\\n#' ? `# **${mainIndex + 1}.** ` + scObj.substring(4) : scObj;
                            let removedLastItem = scObj.replaceAll('"', "'");
                            if (removedLastItem.charAt(removedLastItem.length - 1) === '\\') {
                                newscUpdated.push(removedLastItem.substring(0, removedLastItem.length - 1))
                            } else {
                                newscUpdated.push(removedLastItem)
                            }
                        } else {
                            scObj = scObj.slice(0, 3) === 'n##' ? `## ${mainIndex + 1}.${index} ` + scObj.substring(3) : ` ${mainIndex + 1}.${index} ` + scObj;
                            let removedLastItem = scObj.replaceAll('"', "'");
                            if (removedLastItem.charAt(removedLastItem.length - 1) === '\\') {
                                newscUpdated.push(
                                    removedLastItem.substring(0, removedLastItem.length - 1),
                                );
                            } else {
                                newscUpdated.push(removedLastItem);
                            }
                        }
                    }
                });
                updatedSubSection.push(newscUpdated);
            }
        });
        setSplitedmdData(updatedSubSection);
    };

    const showImgOnFullView = () => {
        setShowFullImg(!showFullImg)
    }

    const LabName = process.env.REACT_APP_LAB_NAME?.toUpperCase()

    function isItCvCueLab() {
        return ["CV_CUE", "CV_CUE_2"].includes(LabName)
    }

    return (
        <div className='directionContainer'>
            <img src={bgImg} alt='Background Image'  className='bgImg'/>
            <div className='main-wrapper' style={{ display: 'flex' }}>
                <Sidebar
                    mdSectionName={mdSectionName}
                    mdId={mdId}
                    subHeadingId={subHeadingId}
                    nextPrevId={nextPrevId}
                    nextPrevData={nextPrevData}
                    changeMdId={(value) => setmdId(value)}
                    changeSubHeadingId={(value) => setSubHeadingId(value)}
                    changeNextPrevId={(value) => setNextPrevId(value)}
                />
                <DirectionContent
                    splitedmdData={splitedmdData}
                    visibleScrollToTopbtn={visibleScrollToTopbtn}
                    nextPrevData={nextPrevData}
                    mdId={mdId}
                    subHeadingId={subHeadingId}
                    nextPrevId={nextPrevId}
                    changeMdId={(value) => setmdId(value)}
                    changeSubHeadingId={(value) => setSubHeadingId(value)}
                    changeNextPrevId={(value) => setNextPrevId(value)}
                    nodeData={props.nodeData}
                    handleDrawerOpen={(value) => setDrawerValue(value)}
                />
                {
                    isOpenDrawer &&
                    <div className='topologyContainer' style={{ border: isCVPorNot() ? '1px solid rgba(76,159,80,.28)' : 'none' }}>
                        {
                            isCVPorNot() ?
                                <div className='topologyContent tourTab' >
                                    {showFullImg &&
                                        <div className='overlay' onClick={() => setShowFullImg(false)}></div>
                                    }
                                    {!showFullImg &&
                                        <button className='enlargeBtn' onClick={() => showImgOnFullView()}>
                                            <OpenInFullIcon className='openFullView' />
                                        </button>
                                    }
                                    <CloudVisionLabs
                                        data={props}
                                    />
                                    {showFullImg &&
                                        <div className='topologyWrapper'>
                                            <div className='labsImage'>
                                                <CloudVisionLabs
                                                    data={props}

                                                />
                                                {
                                                    showFullImg &&
                                                    <CloseFullscreenIcon onClick={() => setShowFullImg(false)} className="closeIcon" />
                                                }
                                            </div>

                                        </div>
                                    }
                                </div>
                                :
                                isItCvCueLab() ?
                                    <a href={process.env.REACT_APP_CV_MWM_URL} target='_blank' rel="noreferrer" className='tourTab'>
                                        <img src={cv_cueImg} width='100%' height='100%' />
                                    </a>
                                    :
                                    <div className="drawer-div tourTab" >
                                        <HandsOnLabTab nodeData={props.nodeData} drawerWidth={DRAWER_WIDTH} />
                                    </div>
                        }
                    </div>
                }

            </div>
        </div>
    )

}

export default MainDirectionMd;