export const cntlObj = {
    id: 'cntl',
    name: 'CNTL',
    imgTag: 'cnt',
    MNNode: [],
    x: 420,
    y: 115,
};

export const getLinksOfNodes = (nodeData) => {
    const links = [];
    nodeData.forEach((node) => {
        const { MNNode } = node;
        MNNode.forEach((linkNode) => {
            links.push({
                start: node.id,
                end: linkNode.id,
            });
        });
    });
    return links.filter((linkObj, index, self) => {
        return (
            index ===
            self.findIndex((temp) => {
                return temp.start === linkObj.start && temp.end === linkObj.end;
            })
        );
    });
};
