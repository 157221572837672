import { useEffect, useState } from "react";
import { topologyLabData } from "../../utils/topologyDataJson";

function CloudVisionLabs(props) {
    const [topologyUrl, setTopologyUrl] = useState('');

    useEffect(()=>{
        let  data = topologyLabData.find(imgObj => imgObj.labName.toUpperCase() === process.env.REACT_APP_LAB_NAME.toUpperCase());
        setTopologyUrl(data.imgUrl);
    },[])

    const addStyle = (coords) => {
        const splitedData = coords.split(',');
        return {
            position:"absolute",
            width: `${splitedData[0]}%`,
            height: `${splitedData[1]}%`,
            top:`${splitedData[2]}%`,
            left:`${splitedData[3]}%`
        }
    }

    const showTopology = () => {
      return <>
        <img style={{ width: '100%' }} alt="logo" className="logo" src={topologyUrl} useMap="#image-map" />
            <map name="image-map">
                {props.data.cvpData.map(area => (
                    area.link.length > 0 ?
                        <a key={area.id}  style={addStyle(area.coords)} href={`${area.link}`}  target="_blank" rel="noreferrer"/> :
                        <area key={area.id}  style={addStyle(area.coords)} onClick={()=> console.log(area.id)}/>
                        
                ))}
            </map>
      </>
    }

    return (
        <div style={{margin:'20px',position:"relative",background:'#fff',borderRadius:'6px' }}>
           {showTopology()}
        </div>
    )
}

export default CloudVisionLabs;