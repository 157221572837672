import logo from '../assets/images/homeLogo.png';
import '../assets/styling/component/Header.scss';
import { Typography } from '@mui/material';
import _ from 'lodash';

function Header() {
    return (
        <div className="header">
            <div className="logo-container">
                <img alt="logo" className="logo" src={logo} />
            </div>
            <Typography variant="h5" className="header-titel">
                {!_.isUndefined(process.env.REACT_APP_TOPOLOGY_HEADING) ? process.env.REACT_APP_TOPOLOGY_HEADING : 'Introduction to DANZ Monitoring Fabric (DMF)'}
            </Typography>
        </div>
    );
}

export default Header;
