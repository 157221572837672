import React  from "react";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

export const SwitchButton = ({isChecked = false,onClickHandler}) => {
    return <div className="switchBtnWrapper">
    <input type="checkbox" className="checkbox" id="checkbox" onChange={() => onClickHandler()} />
    <label htmlFor="checkbox" className="checkbox-label">
    <span className="text" style={isChecked ? { paddingLeft: '30px'} : { paddingRight : '30px',paddingLeft: '5px'}}>{!isChecked ?  'Light Mode' : 'Dark Mode'}</span>
      <span className="ball" style={isChecked ? {left:'5px'} : {left:'27px'}}>
       {!isChecked ? <LightModeIcon /> : <DarkModeIcon /> }
      </span>
    </label>
  </div>
}