import { Box, Modal, Typography, IconButton, Button, Snackbar } from '@mui/material';
import '../../assets/styling/component/AskusModal.scss';
import CloseIcon from '@mui/icons-material/Close';
import InputComponent from '../../common-component/InputComponent';
import { useState } from 'react';
import { isFormDataField } from '../../utils/commonUtil';
import { postContactInfo } from '../../services/nodeServices';

const style = {
    position: 'absolute',
    top: '32%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '1px solid #585858',
    boxShadow: 24,
    borderRadius: 2,
    color: '#333',
};
const tempFormObj = {
    name: '',
    email: '',
    company: '',
    question: '',
};

function AskusModal(prop) {
    const { isOpen, closeAskusModal } = prop;
    const [formObj, setFormObj] = useState(tempFormObj);
    const [isValidForm, setValidForm] = useState(true);
    const [isPopup, setPopup] = useState(false);

    const onChangeInput = (event, formKey) => {
        const formField = formKey.toLowerCase();
        setFormObj({
            ...formObj,
            [formField]: event.target.value,
        });
        setValidForm(true);
        event.preventDefault();
    };

    const onClickSend = () => {
        if (isFormDataField(formObj)) {
            console.log(formObj);
            postContactInfo(formObj).then((response) => {
                if(response){
                    console.log("Success",response);
                    onClickClose();
                    setPopup(true);
                }else{
                    console.log("error",response)
                }
            })
        } else {
            setValidForm(false);
        }
    };

    const onClickClose = () => {
        setFormObj(tempFormObj);
        setValidForm(true);
        closeAskusModal();
    };

    return (
        <>
         <Snackbar
            className={`snack-success`}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isPopup}
            autoHideDuration={5000}
            onClose={() => setPopup(false)}
            message={'Contact info send successfully!'}
            key={'success'}
            action={
              <IconButton size="small" color="inherit" onClick={() => setPopup(false)}>
                <CloseIcon fontSize='small' />
              </IconButton>
            }
          />
        <Modal
            open={isOpen}
            onClose={closeAskusModal}
            aria-labelledby="ask-us-modal-title"
            aria-describedby="ask-us-modal-description"
        >
            <Box sx={style}>
                <div className="modal-header">
                    <Typography variant="h6">Contact</Typography>
                    <IconButton sx={{ p: 0 }} onClick={onClickClose}>
                        <CloseIcon sx={{ color: '#7b7b7b' }} />
                    </IconButton>
                </div>
                <div className="modal-body">
                    <InputComponent
                        inputProps={{
                            type: 'text',
                            value: formObj.name,
                            label: 'Name',
                            onChangeInput: onChangeInput,
                        }}
                    />
                    <InputComponent
                        inputProps={{
                            type: 'email',
                            value: formObj.email,
                            label: 'Email',
                            onChangeInput: onChangeInput,
                        }}
                    />
                    <InputComponent
                        inputProps={{
                            type: 'text',
                            value: formObj.company,
                            label: 'Company',
                            onChangeInput: onChangeInput,
                        }}
                    />
                    <InputComponent
                        inputProps={{
                            type: 'textarea',
                            value: formObj.question,
                            label: 'Question',
                            onChangeInput: onChangeInput,
                        }}
                    />
                </div>
                <div className="modal-footer">
                    <Typography className={isValidForm ? 'show-err' : ''} variant="subtitle2">
                        Please fill out all the form fields
                    </Typography>
                    <Button
                        variant="contained"
                        size="small"
                        className="send-but"
                        onClick={onClickSend}
                    >
                        Send
                    </Button>
                </div>
            </Box>
        </Modal>
        </>
    );
}

export default AskusModal;
