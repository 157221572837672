import { MenuItem, MenuList } from '@mui/material';
import { CaptureClickEvent } from '../../component/google-analytics/CaptureEvents';
import { isAnalyaticsRequired } from '../../utils/commonUtil';

function NetworkNodeMenu(props) {
    const { node, closePopover } = props;
    const onClickNodeMenu = (link,option) => {
        window.open(link, '_blank');
        closePopover();

        if(isAnalyaticsRequired()){
            CaptureClickEvent('Clicked on '+option)
        }
    };
    return (
        <MenuList>
            {node.hyperlink &&
                node.hyperlink.map((actionObj, index) => {
                    const { option, link } = actionObj;
                    return (
                        <MenuItem
                            className="menu-text"
                            key={index}
                            onClick={() => onClickNodeMenu(link,option)}
                        >
                            {option}
                        </MenuItem>
                    );
                })}
        </MenuList>
    );
}

export default NetworkNodeMenu;
