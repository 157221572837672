import { useEffect, useState } from 'react';
import '../../assets/styling/component/tab-component/HandsOnLabTab.scss';
import { getCoordinateForLine } from '../../utils/diagramUtil';
import NetworkNode from '../../common-component/Diagram/NetworkNode';
import { AWAKE_LAB_HEADING } from '../../utils/constantUtil';
import { isCVPorNot } from '../../utils/commonUtil';
// import {tempNodeData} from '../../services/tempResponse';

function HandsOnLabTab(props) {
    const { nodeData } = props;

    const [linkList, setLinkList] = useState([]);
    const [showToolFarm, setShowToolFarm] = useState(true);
    const [size, setSize] = useState(0);
    useEffect(() => {
        setLinkList(linkNodes());
        checkToolFarmData();

        const handleResize = () => {
            const { innerWidth } = window;
            const newSize = Math.floor(innerWidth * 0.45) - 120;
            setSize(newSize);
            //   linkNodes();
        };

        handleResize();

        // Add event listener for resizing
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, [size]);

    const checkToolFarmData = () => {
        let toolFarmData = nodeData.allNode.filter((nodeObj) => parseInt(nodeObj.x) > 82);
        //   console.log('toolFarmData',toolFarmData,nodeData, JSON.stringify(nodeData))
        if (toolFarmData.length === 0) {
            setShowToolFarm(false);
        } else {
            setShowToolFarm(true);
        }
    };

    const attchNodes = () => {
        return (
            nodeData.allNode &&
            nodeData.allNode.map((nodeObj, index) => {
                return <NetworkNode key={index} node={nodeObj} />;
            })
        );
    };

    const linkNodes = () => {
        const links = [];
        nodeData.linkNode &&
            nodeData.linkNode.forEach((link) => {
                const from = getCoordinateForLine(link.start);
                const to = getCoordinateForLine(link.end);
                links.push(
                    <line
                        key={`${link.start}${link.end}`}
                        x1={`${from.X}`}
                        y1={`${from.Y}`}
                        x2={`${to.X}`}
                        y2={`${to.Y}`}
                        className="svg-line"
                    />,
                );
            });
        return links;
    };

    const getHeight = () => {
        const { innerHeight } = window;
        return Math.floor(innerHeight * 0.5);
    };

    return (
        <div className="lab-tab-container" style={{ width: size, height: `${getHeight()}px` }}>
            <div className="diagram-outer">
                <div className="inner" style={{ width: '100%' }}>
                    {!isCVPorNot() && (
                        <>
                            <div className="node-box network">
                                <center className="box-text">Production Network</center>
                            </div>
                            <div className="node-box fabric">
                                <center className="box-text">Monitoring Fabric</center>
                            </div>
                            {showToolFarm && (
                                <div className="node-box farm">
                                    {process.env.REACT_APP_TOPOLOGY_HEADING !==
                                        AWAKE_LAB_HEADING && (
                                        <center className="box-text">Tool Farm</center>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    <svg className="diagram-svg" style={{ width: '100%' }}>
                        {linkList}
                    </svg>
                    {attchNodes()}
                </div>
            </div>
        </div>
    );
}

export default HandsOnLabTab;
