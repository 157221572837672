import { CV_STUDIO_LAB_01, CV_STUDIO_LAB_02, CVP_L3_LAB, CVP_MLAG_LAB, UCN_CVP_L3_LAB, UCN_CVP_MLAG_LAB, UCN_L2_EVPN, UCN_L3_EVPN, UCN_VxLAN } from "./constantUtil";
import MLAG from '../assets/images/MLAG.png';
import Layer3Img from '../assets/images/layer3Cvp.png';
import UCNMLAG from '../assets/images/UcnMLAG.png';
import UCNLayer3 from '../assets/images/Ucnlayer3.png';
import UCN_VxLANImg from '../assets/images/VxLAN_topo.png';
import UCN_L2EvpnImg from '../assets/images/L2EVPN_topo.png';
import UCN_L3EvpnImg from '../assets/images/L3EVPN_topo.png';

let labName = 'labName', imgUrl = 'imgUrl', coords = 'coords';
export const topologyLabData = [
    {
        [labName] : CVP_L3_LAB,
        [imgUrl] : Layer3Img,
        [coords] : ['13,10,21,11.5','13,10,21,76.5','13,10,59,2.6','13,10,60,35','13,10,59,53','13,10,59,84.6','9,6,89,20','10,6,89,71','17,16,0,43']
    },
    {
        [labName]: CVP_MLAG_LAB,
        [imgUrl] : MLAG,
        [coords] : ['14,10,22,9','14,10,21,77','14,10,63,0','14,10,63,33','14,10,63,52','14,10,63,86','9,7,85,19','10,7,86,71.5','17,16,0,43']
    },
    {
        [labName]: UCN_CVP_MLAG_LAB,
        [imgUrl] : UCNMLAG,
        [coords] : ['14,12,5,10','14,12,5,76','14,12,55,1.5','13,12,55,34','13,12,54,52','13,12,54,85','9,8,82,20','9,8,83,71']
    },
    {
        [labName]: UCN_CVP_L3_LAB,
        [imgUrl] : UCNLayer3,
        [coords] : ['14,10,6,11','14,10,6,76','14,10,51,2','14,10,51,34','13,10,51,53','13,10,51,85','9,7,85,20','9,7,85,71.5']
    },
    {
        [labName]: UCN_VxLAN,
        [imgUrl] : UCN_VxLANImg,
        [coords] : ['13,11,5,10.5','13,11,5,75.5','13,11,51,2','13,11,51,34','13,11,51,52','13,11,51,84','9,7,84,21','9,7,84,70.5']
    },
    {
        [labName]: UCN_L2_EVPN,
        [imgUrl] : UCN_L2EvpnImg,
        [coords] : ['13,9,5,11.5','13,9,5,75.5','13,9,46,3','13,9,46,35','13,9,46,52','13,9,46,84','9,7,75,22','9,7,74,71']
    },
    {
        [labName]: UCN_L3_EVPN,
        [imgUrl] : UCN_L3EvpnImg,
        [coords] : ['13,9,5,11.5','13,9,5,75.5','13,9,46,3','13,10,45,35','13,10,45,52','13,10,45,84','9,7,74,22','9,7,74,71']
    },
    {
        [labName] : CV_STUDIO_LAB_01,
        [imgUrl] : Layer3Img,
        [coords] : ['13,10,21,11.5','13,10,21,76.5','13,10,59,2.6','13,10,60,35','13,10,59,53','13,10,59,84.6','9,6,89,20','10,6,89,71','17,16,0,43']
    },
    {
        [labName] : CV_STUDIO_LAB_02,
        [imgUrl] : Layer3Img,
        [coords] : ['13,10,21,11.5','13,10,21,76.5','13,10,59,2.6','13,10,60,35','13,10,59,53','13,10,59,84.6','9,6,89,20','10,6,89,71','17,16,0,43']
    }
]


 