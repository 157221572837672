import _ from 'lodash';
import { topologyLabData } from './topologyDataJson';

export const topologyData = (data) => {
    let topologyObj = [];
    let topologyData = topologyLabData.find(topoObj => topoObj.labName.toUpperCase() === process.env.REACT_APP_LAB_NAME.toUpperCase())
    let coord = topologyData.coords;
    if (!_.isUndefined(coord)) {
        data.allNode.map((obj, index) => {
            if (coord.length > index) {
                let areajson = {};
                areajson.coords = coord[index];
                areajson.id = obj.id;
                areajson.link = obj.hyperlink.length !== 0 ? obj.hyperlink[0].link : '';
                topologyObj.push(areajson)
            }
        })
    }
    return topologyObj;
}

