import '../assets/styling/common-component/InputComponent.scss';

function InputComponent(prop) {
    const {
        inputProps: { type, value, label, onChangeInput },
    } = prop;
    return (
        <div className="input-box">
            <label className="input-label">{label}</label>
            {type !== 'textarea' ? (
                <input
                    required
                    type={type}
                    placeholder={`Your ${label}`}
                    className="custum-input"
                    value={value}
                    onChange={(e) => onChangeInput(e, label)}
                />
            ) : (
                <textarea
                    placeholder={`Your ${label}`}
                    className="custum-input"
                    value={value}
                    required
                    onChange={(e) => onChangeInput(e, label)}
                />
            )}
        </div>
    );
}

export default InputComponent;
