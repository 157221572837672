export const DIRECTION = 'Direction';
export const REF_CONFIG = 'Reference Configuration';
export const AWAKE_LAB_HEADING = 'Introduction to Arista NDR';
export const CVP_L3_LAB = 'CVP-LAYER3';
export const CVP_MLAG_LAB = 'CVP-MLAG';
export const UCN_CVP_MLAG_LAB = 'UCN_MLAG';
export const UCN_CVP_L3_LAB = 'UCN-LAYER3';
export const UCN_VxLAN = 'UCN_VxLAN';
export const UCN_L2_EVPN = 'UCN_L2_EVPN';
export const UCN_L3_EVPN = 'UCN_L3_EVPN';
export const DMF_INTRO = 'DMF-INTRO';
export const CV_STUDIO_LAB_01 = 'CV_STUDIO_LAB_01';
export const CV_STUDIO_LAB_02 = 'CV_STUDIO_LAB_02';