import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ReferenceModal from '../reference-modal/ReferenceModal';
import { Typography, MenuItem, Menu } from '@mui/material';
import { REF_CONFIG } from '../../utils/constantUtil';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import {  EventPageView } from '../google-analytics/CaptureEvents';
import { CopyToClipboard } from 'react-copy-to-clipboard';


function DirectionContent(props) {
    const [isShowReferenceModal, setReferenceModal] = useState(false);
    const [isReference, setReference] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [open, setOpen] = useState(true);
    const [visibleScrollToTopbtn, setVisibleBtn] = useState(false);
    const [copiedKey, setCopiedKey] = useState(false);



    useEffect(() => {
        EventPageView(window.location.pathname);
        document.getElementById('mdSection').addEventListener('scroll', toggleScroller);
        let cntlValue = props.nodeData.allNode.filter(nodeObj => nodeObj.name.toUpperCase() === 'CNTL');
        if (cntlValue.length === 0) {
            setReferenceModal(false)
        } else {
            setReferenceModal(true)
        }
    }, [])

    // Added below function to copy selected content
    const copyContent = () => {
        setCopiedKey(true)
        setTimeout(() => {
            setCopiedKey(false)
        }, 2000)
    }

    // Using below function add copy functionality only for pre tag elements 
    const PreTag = ({ children }) => {
        const content = Array.isArray(children) ? children[0] : children;
        return (
            <div className='preContents'>
                <CopyToClipboard text={content.props.children[0]} onCopy={copyContent}>
                    <button className='copyContentBtn'>
                        {
                            !copiedKey ?
                                <span>
                                    <ContentCopyIcon /> Copy Content
                                </span> :
                                <span>
                                    <CheckIcon /> Copied!
                                </span>
                        }
                    </button>
                </CopyToClipboard>

                <pre>
                    {content}
                </pre>
            </div>
        );
    };


    // Hide show scroll to top button
    const toggleScroller = () => {
        if (document.getElementById('mdSection').scrollTop > 300) {
            setVisibleBtn(true);
        } else {
            setVisibleBtn(false);
        }
    };

    // Scroll To top function
    const handleScrollToTop = () => {
        document.getElementById('mdSection').scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };


    const handleDrawerOpen = () => {
        setOpen(true);
        props.handleDrawerOpen(true)
    };

    const handleDrawerClose = () => {
        setOpen(false);
        props.handleDrawerOpen(false)
    };

    const openReferenceModal = () => {
        handleClosePageMenu();
        setReference(true);
    };

    const closeReferenceModal = () => setReference(false);


    const showMdFileContent = (mdData) => {
        return (
            <>
                {mdData.map((mdObj, index) => (
                    <div key={index.toString()}>
                        {index === 0 && mdFileContent(mdObj, mdData.length === 1 ? 1 : 0)}
                        {(index === props.subHeadingId) && mdFileContent(mdObj, 1)}
                    </div>
                ))}
            </>
        );
    };


    const mdFileContent = (fileData) => {
        let removeFirstSpecialChar = fileData.charAt(0) === "'" ? fileData.slice(1) : fileData;
        let removeExtraBrackets =
            removeFirstSpecialChar.charAt(removeFirstSpecialChar.length - 3) === ']'
                ? removeFirstSpecialChar.substring(0, removeFirstSpecialChar.length - 3)
                : removeFirstSpecialChar;
        let checkLastChar = removeExtraBrackets.charAt(removeExtraBrackets.length - 1) === '\\' ?
            removeExtraBrackets.substring(0, removeExtraBrackets.length - 1) :
            removeExtraBrackets;
        return (
            <>
                <ReactMarkdown components={{ pre: PreTag }}>{JSON.parse('"' + checkLastChar + '"')}</ReactMarkdown>
                {/* {id === 1 && showNextPrevTitles()} */}
            </>
        );
    };

    // const showNextPrevTitles = () => {
    //     let removeLastCharforPrev = props.nextPrevData[props.nextPrevId === 0 ? 0 : props.nextPrevId - 1].subTitle.substring(0, 3) === 'n##' ?
    //         props.nextPrevData[props.nextPrevId === 0 ? 0 : props.nextPrevId - 1].subTitle.slice(4) :
    //         props.nextPrevData[props.nextPrevId === 0 ? 0 : props.nextPrevId - 1].subTitle;

    //     let removeLastCharForNext = props.nextPrevData[props.nextPrevId === props.nextPrevData.length - 1 ? props.nextPrevData.length - 1 : props.nextPrevId + 1].subTitle.substring(0, 3) === 'n##' ?
    //         props.nextPrevData[props.nextPrevId === props.nextPrevData.length - 1 ? props.nextPrevData.length - 1 : props.nextPrevId + 1].subTitle.slice(4) :
    //         props.nextPrevData[props.nextPrevId === props.nextPrevData.length - 1 ? props.nextPrevData.length - 1 : props.nextPrevId + 1].subTitle;

    //     let prevText = removeLastCharforPrev;
    //     let nextText = removeLastCharForNext;
    //     return (
    //         <div className='nextPrevSection'>
    //             {props.nextPrevId !== 0 ? <div className='nextPrevContainer' onClick={() => changeSection('prev',props.nextPrevData[props.nextPrevId === 0 ? 0 : props.nextPrevId - 1], props.nextPrevId === 0 ? 0 : props.nextPrevId - 1)}>
    //                 <span className='title'>Previous</span>
    //                 <span className='heading'>
    //                     {prevText.substring(0, 12) === 'INTRODUCTION' ? 'INTRODUCTION' : prevText}
    //                 </span>
    //             </div> : <div></div>}
    //             {props.nextPrevData.length - 1 !== props.nextPrevId ? <div className='nextPrevContainer' onClick={() => changeSection('next',props.nextPrevData[props.nextPrevId === props.nextPrevData.length - 1 ? props.nextPrevData.length - 1 : props.nextPrevId + 1], props.nextPrevId + 1)}>
    //                 <span className='title'>Next</span>
    //                 <span className='heading'>
    //                     {nextText.substring(0, 12) === 'INTRODUCTION' ? 'INTRODUCTION' : nextText}
    //                 </span>
    //             </div> : <div></div>}
    //         </div>
    //     );
    // };


    // const changeSection = (status,selectedItem, index) => {
    //     if (selectedItem.subIndex === -1) {
    //         //   setmdId(selectedItem.mainIndex);
    //         //   setSubHeadingId(-1);
    //         //   setNextPrevId(selectedItem.mainIndex);
    //         props.changeMdId(selectedItem.mainIndex);
    //         props.changeSubHeadingId(-1);
    //         props.changeNextPrevId(selectedItem.mainIndex);
    //         if (isAnalyaticsRequired()) {
    //             CaptureClickEvent('Clicked On Previous Button ' + selectedItem.subTitle.replace("n##", "section: "))
    //         }

    //     } else {
    //         //   setmdId(selectedItem.mainIndex);
    //         //   setSubHeadingId(selectedItem.subIndex + 1);
    //         //   setNextPrevId(index);
    //         props.changeMdId(selectedItem.mainIndex);
    //         props.changeSubHeadingId(selectedItem.subIndex + 1);
    //         props.changeNextPrevId(index);
    //         if (isAnalyaticsRequired()) {
    //             let labName = !_.isUndefined(process.env.REACT_APP_TOPOLOGY_HEADING) ? process.env.REACT_APP_TOPOLOGY_HEADING : 'DMF Overview';
    //             CaptureClickEvent(labName + ' : ' + (status ==='prev' ? 'Clicked On Previous Button ' : 'Clicked On Next Button ') + selectedItem.subTitle.replace("n##", "section -"))
    //         }
    //     }
    //     handleScrollToTop()
    // }

    const handleOpenPageMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleClosePageMenu = () => {
        setAnchorElUser(null);
    };


    return (
        <div className='directionContent' style={{ width: open ? '50%' : '95%' }}>
            {isShowReferenceModal ?
                <button className={'icon-background hideShowRefBtn'} onClick={handleOpenPageMenu}>
                    <MenuBookIcon />
                </button> :
                <></>
            }
            <div className="post" style={{ display: 'flex' }}>
                <div
                    className="mdSectionWrapper"
                    id="mdWrapper"
                    style={{ width: props.showMenuItem ? '70%' : '100%' }}
                >
                    <div id="mdSection">
                        {props.splitedmdData.map((mdData, index) => (
                            <div key={index.toString()} style={{ width: props.mdId === index ? '100%' : '' }}>
                                {props.mdId === index && (
                                    <div className={`postWrapper ${isShowReferenceModal ? 'addExtraPadding' : ''}`} id="mdxyz">
                                        {showMdFileContent(mdData)}
                                    </div>
                                )}
                            </div>
                        ))}
                        {visibleScrollToTopbtn && (
                            <div className="btn-scroll-top" onClick={() => handleScrollToTop()}>
                                <KeyboardArrowUpIcon />
                            </div>
                        )}
                    </div>
                    {/* {props.nextPrevData.length !== 0 && showNextPrevTitles()} */}

                </div>
            </div>
            <button
                aria-label="open drawer"
                className={'icon-background hideShowTopologyBtn'}
                onClick={!open ? handleDrawerOpen : handleDrawerClose}
                style={{ right: '5px' }}
            >
                <MenuOpenIcon fontSize="medium" className={!open ? 'rotateLeft' : 'rotateRight'} />
            </button>
            <Menu
                sx={{ mt: '40px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleClosePageMenu}
            >
                <MenuItem onClick={openReferenceModal}>
                    <Typography textAlign="center">{REF_CONFIG}</Typography>
                </MenuItem>
            </Menu>
            <ReferenceModal isOpen={isReference} closeReferenceModal={closeReferenceModal} />
        </div>
    )
}

export default DirectionContent;
