import React, { useEffect, useReducer } from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { TOUR_STEPS } from './tourStepsJson';


const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'START':
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
                key: new Date(),
            };;
        case 'RESET':
            return { ...state, stepIndex: 0 };
        case 'STOP':
            return { ...state, run: false };
        case 'NEXT_OR_PREV':
            return { ...state, ...action.payload };
        case 'RESTART':
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
                key: new Date(),
            };
        default:
            return state;
    }
};

const TourGuide = () => {
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    useEffect(() => {
       const timeInterval = setTimeout(() => {
                dispatch({ type: 'START' });
        }, 2000);
        return () => clearTimeout(timeInterval);
    }, []);

    const callback = (data) => {
        const { action, index, type, status } = data;
        if (
            action === ACTIONS.CLOSE ||
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            dispatch({ type: 'STOP' });
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            dispatch({
                type: 'NEXT_OR_PREV',
                payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
            });
        }
    };

    const startTour = () => {
        dispatch({ type: 'RESTART' });
    };

    return (
        <>
            <button className="btn btn-primary startTourBtn" onClick={startTour}>
                Start Tour
            </button>
            <ReactJoyride
                {...tourState}
                callback={callback}
                showSkipButton={true}
                styles={{
                    options: {
                        backgroundColor: '#0969da',
                        arrowColor: '#0969da',
                        textColor: '#fff',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.6)',
                        overlayColor: 'rgba(0, 0, 0, 0.6)',
                    },
                    buttonSkip: {
                        fontSize: 16,
                        fontWeight: 600,
                    },
                    tooltipContainer: {
                        textAlign: 'left',
                        fontSize: 18,
                    },
                    buttonNext: {
                        background: '#16325b',
                        paddingLeft: 14,
                        paddingRight: 14,
                        borderRadius: 4,
                        marginRight: 16,
                        color: '#fff',
                        border: 'none',
                    },
                    buttonBack: {
                        background: '#16325b',
                        paddingLeft: 14,
                        paddingRight: 14,
                        borderRadius: 4,
                        marginRight: 16,
                        color: '#fff',
                    },
                }}
                locale={{
                    last: 'End tour',
                }}
            />
        </>
    );
};

export default TourGuide;