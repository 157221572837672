import { useState } from 'react';
import { Popover } from '@mui/material';
import '../../assets/styling/common-component/NetworkNode.scss';
import NetworkNodeMenu from './NetworkNodeMenu';
import { CaptureClickEvent } from '../../component/google-analytics/CaptureEvents';
import { isAnalyaticsRequired } from '../../utils/commonUtil';
import _ from 'lodash';

function NetworkNode(props) {
    const { node } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const isAction = node.hyperlink && node.hyperlink.length > 0 ? true : false;

    const handleClick = (event, node) => {
        if (isAction) {
            if(node.hyperlink.length > 1){
                setAnchorEl(event.currentTarget);
            }else{
                window.open(node.hyperlink[0].link, '_blank');
            }
        }
        const nodeArr = document.getElementsByClassName('node-focus');
        if (nodeArr && nodeArr.length) {
            nodeArr[0].classList.remove('node-focus');
        }
        event.currentTarget.classList.add('node-focus');

        if(isAnalyaticsRequired()){
            let labName = !_.isUndefined(process.env.REACT_APP_TOPOLOGY_HEADING) ? process.env.REACT_APP_TOPOLOGY_HEADING : 'DMF Overview';
            CaptureClickEvent(labName + ' : ' + 'Clicked on '+node.name)
        }

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const css = {
        left: `${node.x}%`,
        top: `${node.y}%`,
        backgroundImage: node.imgTag
            ? `url(${require(`../../assets/images/node-images/${node.imgTag}.png`)})`
            : '',
        width: node.imgTag === 'cvpImg' ? '14%' : '6.5%',
        height: node.imgTag === 'cvpImg' ? '14%' : '10%',
        backgroundSize: 'contain',
        backgroundPositionY:'bottom'
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <div
                aria-describedby={`popover_${node.id}`}
                id={node.id}
                style={css}
                className="node"
                onClick={(e) => handleClick(e, node)}
            >
                <div className="titel-div">
                    <div className="node-titel">{node.name}</div>
                </div>
            </div>
            <Popover
                id={`popover_${node.id}`}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <NetworkNodeMenu node={node} closePopover={handleClose} />
            </Popover>
        </>
    );
}

export default NetworkNode;
