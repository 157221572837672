import React from 'react';
import '../assets/styling/component/Main.scss';
import Header from './Header.jsx';
import Footer from './Footer.jsx';
import ReactGA from 'react-ga4';
import { getTopologyData } from '../services/nodeServices';
import _ from 'lodash';
import MainDirectionMd from './main-drawer/MainDirectionMd';
import { topologyData } from '../utils/getTopologyData';
import { isCVPorNot } from '../utils/commonUtil';
import Loader from '../utils/loader';
import TourGuide from './tour-guide/tourGuide';
//import { tempNodeData } from '../services/tempResponse.js';
import { GenerateAuthToken } from './generate-token/generateAuthToken.jsx';
import Cookies from 'js-cookie';
import { SwitchButton } from '../utils/switchButton.jsx';

class Main extends React.Component {
    state = {
        nodeData: {},
        isLoading: false,
        cvpData: {},
        isShowData: true,
        isSuccess: true,
        isDarkMode: true,
    };

    setGA = () => {
        const uId = Cookies.get('userId');
        ReactGA.initialize(process.env.REACT_APP_GA4_TAG,{
            gaOptions:{
            userId: uId
        }});
        console.log('uId', uId,Cookies.get());
        ReactGA.set({ user_id: uId });
        //   TagManager.initialize(process.env.REACT_APP_GA4_TAG);
    };

    componentDidMount() {
        this.setGA();

        if (!_.isUndefined(process.env.REACT_APP_TOPOLOGY_HEADING)) {
            document.title = process.env.REACT_APP_TOPOLOGY_HEADING;
        } else {
            document.title = 'DMF Overview';
        }
        getTopologyData().then((data) => {
            console.log(
                'data',
                !data,
                !_.isUndefined(data),
                data,
                process.env.REACT_APP_TOPOLOGY_HEADING,
                process.env,
            );
            if (!data) {
                this.setState({
                    isSuccess: false,
                });
            } else {
                if (data.allNode && data.linkNode) {
                    this.setState({
                        nodeData: data,
                        isLoading: true,
                    });
                }
                if (isCVPorNot()) {
                    this.setState({
                        cvpData: topologyData(data),
                    });
                }
            }
        });

        setTimeout(() => {
            this.setState({
                isShowData: false,
            });
        }, 1500);
    }

    handleChange = () => {
        this.setState({
            isDarkMode: !this.state.isDarkMode,
        });
    };

    render() {
        const { nodeData, isLoading, cvpData, isShowData, isSuccess, isDarkMode } = this.state;
        return (
            <div className={`main-container ${this.state.isDarkMode ? '' : ' lightMode'}`}>
                {isSuccess && <SwitchButton isChecked={isDarkMode} onClickHandler={() => this.handleChange()} />}
                <Header />
                {isSuccess ? (
                    <>
                        {isShowData || !isLoading ? (
                            <Loader />
                        ) : (
                            <>
                                <TourGuide nodeData={nodeData} />
                                <MainDirectionMd nodeData={nodeData} cvpData={cvpData} />
                            </>
                        )}
                    </>
                ) : (
                    <GenerateAuthToken />
                )}
                <Footer isSuccess={isSuccess ? isLoading : false} />
            </div>
        );
    }
}

export default Main;
