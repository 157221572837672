import { useState } from 'react';
import '../assets/styling/component/Footer.scss';
import { Box, Button, Typography } from '@mui/material';
import AskusModal from './askus-modal/AskusModal';

function Footer(props) {
    const [isOpen, setOpen] = useState(false);

    const openAskusModal = () => setOpen(true);

    const closeAskusModal = () => setOpen(false);

    const getYear = () => {
        const date = new Date();
        return date.getFullYear();
    };

    return (
        <div className="footer" style={!props.isSuccess ? {position : 'fixed' , width : '95%'} :  {position : 'inherit' , width : 'auto'}}>
            <div className="content">
                <Box className="ask-div">
                    <Button variant="contained" className="ask-button" onClick={openAskusModal}>
                        Ask Us
                    </Button>
                </Box>
                <Box className="connect-div">
                    <Typography variant="subtitle2" sx={{ lineHeight: '1', paddingLeft: '4.5%' }}>
                        CONNECT
                    </Typography>
                    <div style={{ paddingTop: '3.5%' }}>
                        <a className="icon-a" href="https://www.facebook.com/AristaNW">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a className="icon-a" href="https://twitter.com/AristaNetworks">
                            <i className="fa fa-twitter"></i>
                        </a>
                        <a
                            className="icon-a"
                            href="https://www.linkedin.com/company/arista-networks-inc?challengeId=AQFHNX-VIpFiwwAAAXuBZM4Z-Du0w-XLVE56a62bXPCvj0rdfq9qaLKHyKywWOkBGrTP2tkCgjluqT83uMplbQzlfroI1Cgl4g&submissionId=ddd5134e-24cb-9e16-70ab-5b0f95662351"
                        >
                            <i className="fa fa-linkedin"></i>
                        </a>
                        <a className="icon-a" href="https://www.youtube.com/user/AristaNetworks">
                            <i className="fa fa-youtube"></i>
                        </a>
                    </div>
                </Box>
            </div>
            <div className="copy-right">
                ©{getYear()} Arista Networks, Inc. All rights reserved.
            </div>
            <AskusModal isOpen={isOpen} closeAskusModal={closeAskusModal} />
        </div>
    );
}

export default Footer;
