import axios from 'axios';
import { getLinksOfNodes } from '../utils/nodeUtil';

// eslint-disable-next-line no-undef
//const url = 'https://development-labs-dmf-intro-4643.endpoints.arista-labs-prod.cloud.goog';
const URL = `/backend/mn`;

export const getTopologyData = () => {
    return axios
        .get(`${URL}`)
        .then((response) => {
            if (response.status === 200) {
                const { data } = response;
                if (data && data.length > 0) {
                    return getAllNodeById(data).then((nodeData) => {
                        return { allNode: nodeData, linkNode: getLinksOfNodes(nodeData) };
                    });
                } else {
                    console.log('nodeService.js :: getTopologyData :: Data not found', data);
                    return Promise.resolve(false);
                }
            } else {
                console.log('nodeService.js :: getTopologyData :: Status', response.status);
                return Promise.resolve(false);
            }
        })
        .catch((e) => {
            console.error('nodeService.js :: getTopologyData :: Error', e);
            return Promise.resolve(false);
        });
};

const getAllNodeById = async (nodeData) => {
    const nodeDataById = [];
    for (let i = 0; i < nodeData.length; i++) {
        const { id } = nodeData[i];
        const response = await axios.get(`${URL}/${id}/links`);
        if (response.status === 200 && response.data) {
            nodeDataById.push(response.data);
        } else {
            console.log(`nodeService.js :: getAllNodeById :: ${id} response`, response);
        }
    }
    return nodeDataById;
};


export const  generateToken = async() => {
    const url = process.env.REACT_APP_ARISTA_WEB_URL;  // Get this url from ENV which set from backend
    const endpoint = '/backend/api/v1/lab/genrateToken';
    try {
        const instanceId = getInstanceId();
        const response = await axios.post(url + endpoint, { id: instanceId });
        if (response.status === 200) {
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
      } catch (error) {
        console.error('Error:', error);
        return Promise.resolve(false);
      }
}

const getInstanceId = () => {
    const mainUrl = window.location.href;
    const regex = /-([0-9]+)\.endpoint/;
    const match = mainUrl.match(regex);
    const number = match[1];
    return number;
}


export const postContactInfo = async(data) => {
    const url = process.env.REACT_APP_ARISTA_WEB_URL;  // Get this url from ENV which set from backend
    const endpoint = '/backend/api/v1/contact';
    console.log("url:",url)
    try{
        const response = await axios.post(url + endpoint, data);
        console.log("response",response)
        if(response.status === 200){
            return Promise.resolve(true)
        }else{
            return Promise.resolve(false)
        }
    }catch (error) {
        console.log("error",error)
        return Promise.resolve(false)
    }
}