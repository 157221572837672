export const TOUR_STEPS = [

    {
        target: '.hideShowTopologyBtn',
       content: 'Expand Instructions',
       disableBeacon: true,
    },
   {
       target: '.mdSectionList',
       content: 'Instructions Breakdown',
       placement:'right'
   },
   {
    target: '.hideShowRefBtn',
    content: 'Reference Configuration'
   },
   {
    target: '.nextPrevContainer',
    content: 'Go to the next/previous section'
   },
   {
    target: '.enlargeBtn',
    content:'Open Lab Topology into full screen'
   },
   {
       target: '.tourTab',
       content: 'Lab Topology',
       placement: 'top',
   }
];
