import { CV_STUDIO_LAB_01, CV_STUDIO_LAB_02, CVP_L3_LAB, CVP_MLAG_LAB, UCN_CVP_L3_LAB, UCN_CVP_MLAG_LAB, UCN_L2_EVPN, UCN_L3_EVPN, UCN_VxLAN } from "./constantUtil";
import _ from 'lodash';
export const isFormDataField = (formData) => {
    let isValid = true;
    for (let key in formData) {
        if (formData[key].trim() === '') {
            isValid = false;
            break;
        }
    }
    return isValid;
};

export const isCVPorNot = () => {
    let cvpLabNames = [CVP_MLAG_LAB.toUpperCase(),CVP_L3_LAB.toUpperCase(),
        UCN_CVP_L3_LAB.toUpperCase(),UCN_CVP_MLAG_LAB.toUpperCase(),
        UCN_VxLAN.toUpperCase(), UCN_L2_EVPN.toUpperCase(),UCN_L3_EVPN.toUpperCase(),
        CV_STUDIO_LAB_01.toUpperCase(),CV_STUDIO_LAB_02.toUpperCase()]
    return !_.isUndefined(process.env.REACT_APP_LAB_NAME) ? cvpLabNames.indexOf(process.env.REACT_APP_LAB_NAME.toUpperCase()) !== -1 : false;

}

// Use to check dmf-intro lab is launched or not 
export const isAnalyaticsRequired = () => {
   // let labName = [DMF_INTRO];
   // let isLabNamePresent = !_.isUndefined(process.env.REACT_APP_LAB_NAME) ? labName.indexOf(process.env.REACT_APP_LAB_NAME.toUpperCase()) !== -1 : false;
    //return !_.isUndefined(process.env.REACT_APP_LAB_NAME) ? isLabNamePresent : false;
    return true;
}