import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

export const getDrwaerWidth = () => {
    const { innerWidth } = window;
    return Math.floor(innerWidth * 0.45);
};

export const DRAWER_WIDTH = getDrwaerWidth();
export const minDrawerWidth = 20;
export const maxDrawerWidth = 10 + DRAWER_WIDTH;

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, width }) => ({
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: getPadding(width, open),
        paddingRight: getPadding(width, open),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: 100,
        }),
        marginRight: -width,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: 100,
            }),
            marginRight: 0,
        }),
    }),
);

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, width }) => ({
    backgroundColor: 'transparent',
    top: '52px',
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: 100,
    }),
    ...(open && {
        width: `calc(100% - ${width}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: 100,
        }),
        marginRight: width,
    }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
    justifyContent: 'space-between',
    minHeight: '35px',
}));

const getPadding = (width, isOpen) => {
    if (isOpen) {
        if (width >= 601) {
            return '2vh';
        } else if (width >= 301 && width <= 600) {
            return '4vh';
        } else if (width >= 20 && width <= 300) {
            return '10vh';
        }
        return '14vw';
    } else {
        return '14vw';
    }
};
